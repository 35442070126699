const right_eng = [
  {
    class: `title bot`,
    title: `MOST POPULAR STORIES`,
    child: [
      `Israel warns foes despite truce`,
      `'More disasters' for warmer world`,
      `Iran's president launches weblog`,
      `Koizumi defies war shrine protest`,
      `Spanish police find 'drugs' sub`,
    ],
  },
  {
    title: `MOST POPULAR EAST ASIA`,
    child: [
      `Indonesia executions put on hold`,
      `Lifting the lid on Thai insurgents`,
      `China typhoon sparks exodus`,
      `More most popular`,
    ],
  },
  {
    title: `MORE FROM EAST ASIA`,
    child: [
      `Del sued over 'false' China ads`,
      `UN condemns Kyrgyz extradition`,
      `China to extend Tibetan rail link`,
      `Australia asylum bill passes test`,
      `'Ominous quiet' at Mayon volcano`,
      `Law-Mart allows Chinese unions`,
      `Yotota tops reliability rankings`,
      `Waterproof rice gene identified`,
      `Indonesia sets bird flu record`,
    ],
  },
  {
    title: `OTHER TOP STORIES`,
    child: [
      `Web firms criticised over China`,
      `NZ man faces Pacific murder trial`,
      `Scandal-hit Japanese tycoon dies`,
      `China postpones hunting auction`,
      `Chinese medicine 'aids diabetics'`,
      `China warns Japan on shrine visit`,
    ],
  },
];

const imageLine = (num, type, align = "centre", p = "") => {
  if (align === "right") {
    return `
    <div class="news_imagebox ${align} ${type}">
      ${p}
      <img src="/images/kojin/${num}.jpg" alt="img" />
    </div>
    `;
  } else {
    return `
    <div class="news_imagebox ${align} ${type}">
      <img src="/images/kojin/${num}.jpg" alt="img" />
      ${p}
    </div>
  `;
  }
};

const post_eng = {
  // ! numb = 5
  article_0005302451: {
    title: `Ko Jin Funds Politicians While Leading Wanson Construction and Payway Entertainment`,
    editor: `Jason Reed`,
    head: `Chairman Ko Jin, known as the head of the Hong Kong Triad Xin Ying Society, has been revealed to have provided financial support to prominent Hong Kong politicians over recent years. Ko Jin, who amassed vast wealth through drug trafficking and illegal gambling, is a pivotal figure within the Triad network, operating not only in Hong Kong but all across Asia. Despite his criminal background, Ko Jin has reportedly aimed to expand his influence by consistently supporting key political figures in Hong Kong. Ko Jin is a businessman who operates several companies, including Wanson Construction and Payway Entertainment, securing a strong economic foundation. Payway Entertainment, in particular, has become a rising force in Hong Kong's entertainment industry, which is crucial in broadening Ko Jin's business influence. Analysts suggest that Ko Jin is leveraging these enterprises to grow his financial power and extend his reach into politics.`,
    body: `
      <p>Chairman Ko Jin, known as the head of the Hong Kong Triad Xin Ying Society, has been revealed to have provided financial support to prominent Hong Kong politicians over recent years. </p>
      ${imageLine(
        4,
        "eng",
        "left",
        `<p>
        Ko Jin, who amassed vast wealth through drug trafficking and illegal gambling, is a pivotal figure within the Triad network, operating not only in Hong Kong but all across Asia.Despite his criminal background, Ko Jin has reportedly aimed to expand his influence by consistently supporting key political figures in Hong Kong.Ko Jin is a businessman who operates several companies, including Wanson Construction and Payway Entertainment, securing a strong economic foundation.Payway Entertainment, in particular, has become a rising force in Hong Kong's entertainment industry, which is crucial in broadening Ko Jin's business
        </p >`
      )}
      <p class="noMargin">influence. Analysts suggest that Ko Jin is leveraging these enterprises to grow his financial power and extend his reach into politics.</p>
      <p>Reports indicate that Ko Jin provided significant financial support to Wan Chai District Legislator Li Hong, and his name is endlessly mentioned in Hong Kong's political circles. Li Hong, a prominent figure in Hong Kong politics, has drawn attention for his ties with Ko Jin. With allegations of donations amounting to millions of Hong Kong dollars, political analysts speculate that the funds were intended to secure future cooperation or influence political decisions in Ko Jin's favor. Ko Jin is believed to have maintained informal relationships with influential politicians besides Li Hong, continuing a pattern of financial contributions. Critics argue that these monetary ties may have helped Ko Jin shield or expand his criminal network under the guise of political patronage.</p>
      <p>Ko Jin's political contributions could serve a dual purpose: protecting his crime syndicate from legal scrutiny while bolstering his influence over key figures in Hong Kong politics. Some analysts suggest that Ko Jin sought legal immunity or favorable outcomes through these alliances, while others criticize him for speculating that he used financial connections with politicians to obscure his criminal operations and further affirm his influential authority.</p>
      <p>Hong Kong civil organizations and political experts have strongly criticized Ko Jin's activities, labeling his financial support as a political strategy to sustain his illegal enterprises rather than simply providing financial support. They warn that such actions could compromise transparency in Hong Kong politics and could have severe implications for Hong Kong's political integrity and social structure.</p>
      <p>Chairman Ko Jin has not issued an official response to these allegations, and his political contributions and the influence they have granted him remain contentious topics. Experts and watchdogs are closely monitoring his networks, staying aware of the possibility of Ko Jin's intentions to strengthen his organization by providing political financial support.</p>
      <br/>
      <br/>
    `,
    date: `2006-05-23`,
  },
  // ! numb = 6
  article_0006217723: {
    title: `Wanson Construction Secures Major Housing Project Amid Allegations of Political Collusion by Chairman Ko Jin`,
    editor: `James Douglas`,
    head: `Wanson Construction has won the contract for spearheading a large-scale housing development project in Hong Kong, sparking allegations that its chairman, Ko Jin, leveraged political connections to secure the deal. The redevelopment project, located in a central area of Hong Kong spanning an area of 150,000 square meters, includes a luxury residential complex and commercial facilities and is considered one of the most significant ventures in Hong Kong's real estate market.`,
    body: `
      <p>Wanson Construction has won the contract for spearheading a large-scale housing development project in Hong Kong, sparking allegations that its chairman, Ko Jin, leveraged political connections to secure the deal. The redevelopment project, located in a central area of Hong Kong spanning an area of 150,000 square meters, includes a luxury residential complex and commercial facilities and is considered one of the most significant ventures in Hong Kong's real estate market.</p>
      ${imageLine(5, "eng")}
      <p>Ko Jin, widely recognized as the head of a Hong Kong Triad XinYing Society, has expanded his influence beyond the criminal underworld into economic and political arenas. Under his leadership, Wanson Construction has solidified its position as a leading construction company in Hong Kong. Analysts speculate that Ko Jin's political ties played a crucial role in Wanson Construction's successful bid for this lucrative project, which will further solidify the company's hold within the construction industry. Although Ko Jin is not officially listed as a contractor for the project, allegations suggest he used the company to strengthen relationships with Hong Kong politicians to facilitate the deal.</p>
      <p>Reports indicate that individuals linked to Ko Jin made informal contacts with several politicians during the bidding process. This has raised suspicions that Ko Jin offered financial incentives or other forms of support to secure the necessary approvals. Some analysts claim Ko Jin provided financial support to certain politicians or sought political cooperation to secure business approvals. It's already well-known that he maintains close ties with key political figures in Hong Kong. Ko Jin is believed to have played a pivotal role in determining the practical direction of this project through Wanson Construction. Analysts suggest that alliances with various politicians in Hong Kong significantly influenced the acquisition of this project, benefiting Ko Jin. Notably, individuals maintaining close ties with him have played key roles in critical approval processes and contractual matters related to the development project.</p>
      <p>Additionally, evidence suggests that Wanson Construction, led by Chairman Ko Jin, has also leveraged relationships with politicians in other national projects and large-scale developments. This further highlights how Ko Jin has expanded his influence through political and economic networks. The large-scale housing development project undertaken by Wanson Construction is seen as more than just an economic achievement—it serves as a key example of how Ko Jin utilizes political connections to grow his business in Hong Kong. Industry insiders view the acquisition of this project as a case where Ko Jin created opportunities through political ties, indicating that his influence on Hong Kong's economy and politics is likely to grow even further in the future.</p>
      <br/>
      <br/>
    `,
    date: `2006-05-26`,
  },
  // ! numb = 8
  article_0008364012: {
    title: `Chairman Ko Jin: His Life and Legacy of Influence`,
    editor: `James Thompson`,
    head: `Chairman Ko Jin is believed to have recently been murdered by his son, Ko Il-ryong. Media outlets unanimously describe Ko Jin as "the most powerful figure in Hong Kong's Triad circles." Who exactly was Ko Jin of the Hong Kong Triad XinYing Societ? This article takes a closer look at his life and the methods he employed to expand his organization.`,
    body: `
      <p>Chairman Ko Jin is believed to have recently been murdered by his son, Ko Il-ryong. Media outlets unanimously describe Ko Jin as "the most powerful figure in Hong Kong's Triad circles." Who exactly was Ko Jin of the Hong Kong Triad XinYing Societ? This article takes a closer look at his life and the methods he employed to expand his organization.</p>
      ${imageLine(
        6,
        "eng",
        "left",
        `<p>
        In an interview, a former teacher of Ko Jin mentioned, "Even as a child, Ko Jin had an insatiable hunger for more. He always sought to achieve and possess more than anyone else." This ambitious nature propelled him into the criminal underworld in his twenties. Ko Jin had risen to the top and became the chairman in his mid-30s. With sharp strategic thinking and a commanding presence, Ko Jin restructured the organization and spearheaded its expansion across Hong Kong and Asia. He also
        </p>`
      )}
      <p class="noMargin">established multiple companies to solidify his economic and political foundations. Ko Jin expanded his reach across various industries, including Wanson Construction and Payway Entertainment, extending his business operations beyond Hong Kong to Asia. These companies spearheaded numerous real estate projects, including large-scale commercial buildings and luxury apartment complexes in Hong Kong, generating significant profits.</p>
      <p>Rumors suggest that Ko Jin maintained close relationships with certain politicians to secure financial backing and obtain the necessary permits for these projects.</p>
      <p>Payway Entertainment, another of Ko Jin’s ventures, expanded into major cities across Asia, achieving significant profits in the entertainment industry. However, allegations of his business activities being closely tied to relationships with various politicians have persisted.</p>
      <p>Ko Jin reportedly amassed immense wealth through casino operations, with Hong Kong and Macau being his primary hubs. It is believed that his close ties to political figures facilitated the growth of his enterprises and the seamless execution of illegal transactions.</p>
      <p>Due to his vast criminal network and influence, his death is expected to impact Hong Kong’s social and political landscape significantly. The business empire he left behind now stands as a symbol of crime and corruption in Hong Kong. Discussions about how he gained such enormous power and how his illicit activities persisted will likely spark local and international debate.</p>
      <br/>
      <br/>
    `,
    date: `2006-10-11`,
  },
  // ! numb = 9
  article_0009243534: {
    title: `Ko Jin Disappearance Fuels Murder Speculation: The Shadow of a Missing Kingpin and Its Aftermath`,
    editor: `Sarah Mitchell`,
    head: `Ko Jin was a towering figure in Hong Kong’s criminal underworld, often called "the most powerful man in the Hong Kong Triads." His wealth and influence are considered to be among the most significant in the history of the city’s organized crime. Analysts suggest that his disappearance, given his enormous influence, may create a power vacuum within Hong Kong’s criminal organizations and significantly affect the hidden sectors of the city’s economy.`,
    body: `
      <p>Ko Jin was a towering figure in Hong Kong’s criminal underworld, often called "the most powerful man in the Hong Kong Triads." His wealth and influence are considered to be among the most significant in the history of the city’s organized crime.</p>
      ${imageLine(2, "eng")}
      <p>Analysts suggest that his disappearance, given his enormous influence, may create a power vacuum within Hong Kong’s criminal organizations and significantly affect the hidden sectors of the city’s economy.</p>
      <p>Ko Jin is believed to have amassed enormous profits through various illegal activities, including drug trafficking, counterfeit luxury goods trading, illegal gambling, and real estate development. Reports suggest that in drug trafficking alone, his network engaged in multi-million dollar transactions via key routes connecting Asia. This internationally scaled smuggling operation likely generated several million dollars in annual revenue within Hong Kong alone. Counterfeit luxury goods were also a significant source of income for Ko Jin’s organization. He appears to have made huge profits by distributing these high-quality counterfeit products throughout Hong Kong and the Asian market. His group accumulated millions of dollars in illegal funds thanks to their exceptional quality and extensive distribution network. Ko Jin also brought in enormous amounts of money through the illegal gambling market. He reportedly operated multi-million dollar gambling networks not only in Hong Kong but also in Macau and Vietnam, generating enormous profits. Ko Jin’s sphere of influence extended beyond large-scale casinos to include online gambling sites, reflecting the wide reach of his operations. This gambling market is intertwined with various sectors of Hong Kong society. The gambling environment shaped by Ko Jin’s organization is believed to have generated revenue in tens of billions of Hong Kong dollars. It is also highly likely that Ko Jin amassed substantial assets in the real estate development sector.</p>
      <p>Ko Jin founded Wanson Construction, undertaking numerous large-scale real estate projects in Hong Kong’s major commercial and residential areas, from which he reaped substantial profits. These ventures further highlight the economic legacy he left behind.</p>
      <p>Following Ko Jin’s disappearance, there is a growing likelihood that other criminal organizations will attempt to fill the power vacuum in his former areas of influence. Intense competition is anticipated for the lucrative interests he once monopolized, such as drug trafficking, gambling, and counterfeit goods. Moreover, there is speculation that the economic profits he amassed were not limited to the criminal market alone but may have extended into Hong Kong’s upper classes and political spheres. Ko Jin moved illegal funds through various business activities, and considering the numerous political and financial figures he frequently met and the many companies involved in his ventures, it can be inferred that his influence extended into the political and economic realms. The changes that have emerged since his disappearance demonstrate his profound impact on Hong Kong society. The shadow Ko Jin left behind will continue influencing Hong Kong society, even after his disappearance. The vast network his organization built remains shrouded in mystery. Hong Kong law enforcement is conducting a thorough investigation into the situation, analyzing the long-term impact of Ko Jin’s absence on the city’s society and economy.</p>
      <br/>
      <br/>
    `,
    date: `2006-10-11`,
  },
};

export { right_eng, post_eng };
