const calcFileSize = (arr) => {
  let result = 0;
  arr.forEach((e) => {
    if (e[1]) {
      const num = Number.parseFloat(e[1].slice(0, -2)) || 0;
      const type = e[1].slice(-2) || "KB";

      if (type === "GB") {
        result += num * 1000000;
      } else if (type === "MB") {
        result += num * 1000;
      } else if (type === "KB") {
        result += num;
      } else {
        return;
      }
    }
  });

  if (result > 1000000) {
    return (result / 1000000).toFixed(1) + "GB";
  } else if (result > 1000) {
    return (result / 1000).toFixed(1) + "MB";
  } else {
    return result + "KB";
  }
};

const jaritsu = (num) => {
  let str = `${num}`;
  let result = "";

  for (let i = 1; i < str.length + 1; i++) {
    result = str[str.length - i] + result;

    if (i % 3 === 0 && i < str.length) {
      result = "," + result;
    }
  }

  return result;
};

const strongStr = (str) => {
  return "<b>" + str + "</b>";
};

const locationChange = (loca) => {
  window.scrollTo(0, 0);
  window.location = loca;
};

export {
  calcFileSize,
  jaritsu,
  strongStr,
  locationChange,
};
