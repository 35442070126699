import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { locationChange } from "../../common/modules/js/commonFunc";
import { ogresult } from "../../common/contents/ogresult";
import OgResult from "./OgReulst";

function OldgooSearch({ p }) {
  const [query, setQuery] = useSearchParams();

  const search = useRef(query.get('q'));

  const title = `Anirch`;
  const keypoint = `Ko Jin ko jin`;

  useEffect(() => {
    document.title = title;

    if (p) {
      setQuery(`good`)
    }
  })

  return (
    <div className="Oldgoo OldgooSearch">
      <div className="og_login">
        <div className="bb">Web History</div>
        <div className="ll">|</div>
        <div className="bb">Settings</div>
        <div className="ll">|</div>
        <div className="bb end">Sign in</div>
      </div>

      <div className="OldgooContainer">
        <div className="og_search">
          <div className="head">
            <div className="logo" onClick={() => locationChange(`/`)} style={{cursor: "pointer"}}>
              <img src="/images/oldgoo_logo_rgb.png" alt="logo" />
            </div>

            <div className="search">
              <div className="top">
                <div className="bb bold blacked">Web</div>
                <div className="bb">Images</div>
                <div className="bb">Groups</div>
                <div className="bb">News</div>
                <div className="bb">Products</div>
                <div className="bb">Maps</div>
                <div className="bb bold">more »</div>
              </div>

              <div className="bot">
                <input type='text' autoComplete='off' spellCheck='false' defaultValue={search.current} />
                <button>Search</button>
                <div className="right">
                  <div className="bb">Advanced Search</div>
                  <div className="bb">Preferences</div>
                </div>
              </div>
            </div>
          </div>

          <div className="body">
            <div className="line">
              <div className="tt">Web</div>
              <div className="fBlank"></div>
              <div className="info">
                <span>Results</span>
                <b>1</b>
                <span>-</span>
                <b>10</b>
                <span>of about</span>
                <b>34,700</b>
                <span>for</span>
                <b>{search.current}</b>
                <div className="bb"><b>results</b></div>
              </div>
            </div>

            {ogresult.map((e, i) => {
              return (
                <OgResult
                  key={`ogresult_key_${i}`}
                  obj={e}
                  keypoint={keypoint}
                />
              )
            })}
          </div>

          <div className="foot">
            <div className="page">
              <div className="bb bold blacked">1</div>
              <div className="bb">2</div>
              <div className="bb">3</div>
              <div className="bb">4</div>
              <div className="bb">5</div>
              <div className="bb">6</div>
              <div className="bb">7</div>
              <div className="bb">8</div>
              <div className="bb">9</div>
              <div className="bb">10</div>
              <div className="bb bold">{`Next >`}</div>
            </div>

            <div className="btns">
              <div className="bb">Search Help</div>
              <div className="bb">Advertising Programs</div>
              <div className="bb">Business Solutions</div>
            </div>

            <div className="btns">
              <div className="bb">Privacy & Terms</div>
              <div className="bb">Give us feedback</div>
              <div className="bb">About {title}</div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default OldgooSearch;