import './App.css';
import './components/News/NewsEng.css';
import './components/News/NewsChina.css';

function App() {
  return (
    <div className="App">
      google
    </div>
  );
}

export default App;
