import './NewsChina.css';

import { useParams } from 'react-router-dom';
import { v4 } from "uuid";

import { post_cn, right_1 } from '../../common/contents/cn';
import { useEffect, useState } from 'react';

function NewsChina1() {
  const { newsId } = useParams();

  const [obj, setObj] = useState(null);

  const NewsCnBody = ({ obj }) => {
    const autofillId = `neb_auto_${v4()}`;

    const [y, m, d] = obj.date.split('-');

    useEffect(() => {
      document.getElementById(autofillId).innerHTML = obj.body;
    })

    return (
      <div className="main">
        <div className="title">
          {obj.title}
        </div>

        <div className="editor">
          {`记者：${obj.editor}`}
        </div>

        <div className="info">
          <div className="date">
            {`${y}年 ${m}月 ${d}日`}
          </div>

          <div className="fBlank"></div>

          <div className="btns">
            【字号 大 中 小】
          </div>
          <div className="btns">
            【留言】
          </div>
          <div className="btns">
            【论坛】
          </div>
          <div className="btns">
            【打印】
          </div>
          <div className="btns">
            【关闭】
          </div>
        </div>

        <div className="post" id={autofillId}>
          {/* auto fill */}
        </div>
      </div>
    )
  };

  useEffect(() => {
    if (!obj) {
      setObj(post_cn[newsId]);
    }

    document.title = "CCD 中央新聞";
  }, [obj, newsId]);

  return (
    <div className="NewsChina1">
      <div className="nc_con">
        <div className="top">
          <img src="/images/cn1/top.png" alt="" />
        </div>

        <div className="body">
          <div className="left">
            <div className="top">
              <div style={{color: "blue", marginRight: "5px"}}>■</div>
              <span>中央新聞</span>
              {` >> `}
              <span>新闻频道</span>
              {` >> `}
              <span>中国新闻</span>
              {` >> `}
              <span>正文</span>
            </div>

            {
              obj ?
              <NewsCnBody obj={obj} />
              :
              <></>
            }
          </div>

          <div className="right">
            <div className="block rank">
              <div className="title">热点新闻排行</div>
              {
                right_1.map((e, i) => {
                  return (
                    <div className="bb" key={`cn_right_1_key_${i}`}>
                      <div className="num">{String(i + 1)}</div>
                      <div className="btn">{e}</div>
                    </div>
                  )
                })
              }
              <div className="next">...更多</div>
            </div>

            <div className="block search">
              <input type='text' />
              <button>新闻搜索</button>
              <div className="check">
                <input type='checkbox' name='ch1' defaultChecked/>
                <label htmlFor="ch1">中央网搜索</label>
                <div className="fBlank"></div>
                <input type='checkbox' name='ch2' defaultChecked/>
                <label htmlFor="ch2">互联网搜索</label>
              </div>
            </div>

            <div className="block banner">
              <img src="/images/cn1/banner.png" alt="ba" />
            </div>

            <div className="block dog">
              <div className="top">
                <div className="title">搜狗搜索</div>
                <div className="mid">
                  <input type='text' />
                  <button>搜索</button>
                </div>
                <div className="yy">每日音乐排行榜</div>
              </div>
            </div>

            <div className="block rec">
              <div className="list">
                <img src="/images/cn1/banner2.png" alt="ba2" />
                <div className='bb'>·韩国亲子装卖疯了</div>
                <div className='bb'>·快感！暴赚可爱钱！！</div>
                <div className='bb'>·快看！资源再生的法宝</div>
                <div className='bb'>·孩子的钱——稳赚</div>
                <div className='bb'>·改造女人能赚多少钱</div>
                <div className='bb'>·环保行业火爆年利百万</div>
                <div className='bb'>·亲子装店 女人抢着开</div>
                <div className='bb'>·零加盟费利润空间63亿</div>
                <div className='bb'>·送25万启动市场！</div>
                <div className='bb'>·揭秘！女裤专卖火爆！</div>
                <div className='bb'>·亲子装店 女人抢着开</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsChina1;