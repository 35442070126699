import { useState } from 'react';
import { useRouteError } from 'react-router-dom';
import './ErrorPage.css';
// import useInterval from '../../common/useInterval';
import useInterval from '../../common/modules/react/useInterval';

function ErrorPage() {
  const error = useRouteError();
  const [remainTime, setRemainTime] = useState(5);

  useInterval(() => {
    if(remainTime <= 0) {
      window.location.pathname = "/";
      return;
    } else {
      setRemainTime(remainTime - 1);
    }
  }, 1000);

  return (
    <div className="ErrorPage">
      <h1>Error</h1>
      <h2>{"Redirected to the homepage in " + remainTime + " seconds"}</h2>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || "status null"}</i>
      </p>
      <p>
        <i>{error.error.message || "message null"}</i>
      </p>
      <p>
        <i>{error.data || "data null"}</i>
        {/* <i>{error.error.stack || "stack null"}</i> */}
      </p>
    </div>
  );
}

export default ErrorPage;