import { post_cn } from "./cn";
import { post_eng } from "./en";

const chnArr = Object.keys(post_cn);

// * eng num = > 5 6 8 9
const engArr = Object.keys(post_eng);

const isTest = false;

const getLoca = (type, str, isTest) => {
  if (type === 'eng') {
    if (isTest) {
      return `http://localhost:3000/en/${str}`;
    }

    return `https://www.dc-tribune.com/en/${str}`;
  }

  if (type === 'chn') {
    if (isTest) {
      return `http://localhost:3000/cn/${str}`;
    }

    return `https://www.chinacentraldaily.com/cn/${str}`;
  }

  return `about:blank`;
}

const ogresult = [
  {
    title: post_cn[chnArr[0]].title,
    text: post_cn[chnArr[0]].head,
    loca: getLoca(`chn`, chnArr[0], isTest),
  },
  {
    title: post_cn[chnArr[1]].title,
    text: post_cn[chnArr[1]].head,
    loca: getLoca(`chn`, chnArr[1], isTest),
  },
  {
    title: post_cn[chnArr[2]].title,
    text: post_cn[chnArr[2]].head,
    loca: getLoca(`chn`, chnArr[2], isTest),
  },
  {
    title: post_cn[chnArr[3]].title,
    text: post_cn[chnArr[3]].head,
    loca: getLoca(`chn`, chnArr[3], isTest),
  },
  {
    title: post_eng[engArr[0]].title,
    text: post_eng[engArr[0]].head,
    loca: getLoca(`eng`, engArr[0], isTest),
  },
  {
    title: post_eng[engArr[1]].title,
    text: post_eng[engArr[1]].head,
    loca: getLoca(`eng`, engArr[1], isTest),
  },
  {
    title: post_cn[chnArr[4]].title,
    text: post_cn[chnArr[4]].head,
    loca: getLoca(`chn`, chnArr[4], isTest),
  },
  {
    title: post_eng[engArr[2]].title,
    text: post_eng[engArr[2]].head,
    loca: getLoca(`eng`, engArr[2], isTest),
  },
  {
    title: post_eng[engArr[3]].title,
    text: post_eng[engArr[3]].head,
    loca: getLoca(`eng`, engArr[3], isTest),
  },
  {
    title: post_cn[chnArr[5]].title,
    text: post_cn[chnArr[5]].head,
    loca: getLoca(`chn`, chnArr[5], isTest),
  },
];

export { ogresult };
