import { useEffect, useRef } from 'react';
import './Oldgoo.css';
import { locationChange } from '../../common/modules/js/commonFunc';

function Oldgoo() {
  const title = `Anirch`;
  
  const v = useRef('');

  const nextPage = (str) => {
    locationChange(`/search?q=${str}`)
  }

  const onChangeFunc = (e) => {
    v.current = e.target.value;
  }

  const inputEnter = (e) => {
    if (e.keyCode === 13) {
      nextPage(v.current);
    }
  }

  useEffect(() => {
    document.title = title;
  })

  return (
    <div className="Oldgoo">
      <div className="og_login">
        <div className="bb">Personalized Home</div>
        <div className="ll">|</div>
        <div className="bb end">Sign in</div>
      </div>

      <div className="OldgooContainer">
        <div className="og_home">
          <img className='logo' src='/images/oldgoo_logo_rgb.png' alt='logo' />

          <div className="og_home_menu">
            <div className="bb bold blacked">Web</div>
            <div className="bb">Images</div>
            <div className="bb">Groups</div>
            <div className="bb">News</div>
            <div className="bb">Products</div>
            <div className="bb">Maps</div>
            <div className="bb bold">more »</div>
          </div>

          <div className="og_serach_box">
            <div className="side"></div>
            <div className="main">
              <div className="top">
                <input type='text' autoComplete='off' spellCheck='false' onKeyDown={inputEnter} onChange={onChangeFunc} />
              </div>
              <div className="bot">
                <button onClick={() => nextPage(v.current)}>{title} Search</button>
                <button>I'm Feeling Good</button>
              </div>
            </div>
            <div className="side">
              <div className="bb">Advanced Search</div>
              <div className="bb">Preferences</div>
              <div className="bb">Language Tools</div>
            </div>
          </div>

          <div className="og_foot">
            <div className="btns">
              <div className="bb">Advertising Programs</div>
              <div className="ll">-</div>
              <div className="bb">Business Solutions</div>
              <div className="ll">-</div>
              <div className="bb">About {title}</div>
            </div>

            <div className="end">
              © {title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Oldgoo;