import { useEffect, useState } from "react";
import "./NewsEng.css";
import { post_eng, right_eng as right } from "../../common/contents/en";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

function NewsEng1() {
  const { newsId } = useParams();

  const [obj, setObj] = useState(null);

  const NewsEngBody = ({ obj }) => {
    const autofillId = `neb_auto_${v4()}`;

    useEffect(() => {
      document.getElementById(autofillId).innerHTML = obj.body;
    });

    return (
      <div className="NewsEngBody">
        <div className="title">{obj.title}</div>

        {
          obj.editor ?
            <div className="editor"><span>{obj.date + ' | Reported By '}</span>
              {obj.editor}<span>, Washington Tribune</span></div>
          :
          <></>
        }

        <div className="body" id={autofillId}>
          {/* auto fill */}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!obj) {
      setObj(post_eng[newsId]);
    }

    document.title = "Washington Tribune";
  }, [obj, newsId]);

  return (
    <div className="NewsEng1">
      <div className="ne1_header">
        <div className="head">
          <div className="btn">Home</div>
          <div className="btn selected">News</div>
          <div className="btn">Sport</div>
          <div className="btn">Radio</div>
          <div className="btn">TV</div>
          <div className="btn">Weather</div>

          <div className="fBlank"></div>

          <div className="search_box">
            <input type="text" />
            <button style={{ marginLeft: "5px" }}>Search</button>
          </div>
        </div>
      </div>

      <div className="ne1_body">
        <div className="top">
          <img src="/images/en1/top.png" alt="top" />
          <div className="btns">
            <div className="bb">News Front Page</div>
            <div className="fBlank"></div>
            <div className="bb">About the versions</div>
            <div className="ll">|</div>
            <div className="bb">Low graphics</div>
            <div className="ll">|</div>
            <div className="bb">Help</div>
            <div className="ll">|</div>
            <div className="bb">Contact us</div>
          </div>
        </div>

        <div className="main">
          <div className="left">
            <img src="/images/en1/world_asia.png" alt="map" />
            <div className="bb bold">North America</div>
            <div className="bb bold">South America</div>
            <div className="bb bold">Europe</div>
            <div className="bb bold">Africa</div>
            <div className="bb bold">Middle East</div>
            <div className="bb bold selected">East Asia</div>
            <div className="bb bold">South Asia</div>
            <div className="bb bold">Business</div>
            <div className="bb bold">Health</div>
            <div className="bb bold">Science/Nature</div>
            <div className="bb bold">Technology</div>
            <div className="bb bold">Entertainment</div>
            <div className="sl none">-----------------</div>
            <div className="bb">Have Your Say</div>
            <div className="bb">In Pictures</div>
            <div className="bb">Country Profiles</div>
            <div className="bb">In Depth</div>
            <div className="bb">Programmes</div>
            <div className="sl none"></div>
            <div className="sl bold">RELATED SITES</div>
            <div className="bb">SPORT</div>
            <div className="bb">WEATHER</div>
            <div className="bb">ON THIS DAY</div>
            <div className="bb">EDITOR'S BLOG</div>
            <div className="sl none"></div>
            <div className="sl bold">LANGUAGES</div>
            <div className="bb">Russian</div>
            <div className="bb">Polish</div>
            <div className="bb">Serbian</div>
            <div className="bb">Turkish</div>
            <div className="bb bold">{`< More`}</div>
            <div className="sl none"></div>
          </div>

          <div className="centre">
            <div className="btns">
              <div className="bb">● E-mail this to a friend</div>
              <div className="bb">● Printable version</div>
            </div>

            {
              obj ?
              <NewsEngBody obj={obj} />
              :
              <></>
            }

            <div className="btns">
              <div className="bb">● Have your say</div>
              <div className="bb">● News Front Page</div>
              <div className="bb">● In Depth</div>
            </div>
          </div>

          <div className="right">
            {
              right.map((e, i) => {
                return (
                  <div className={e.class ? e.class + " box" : "box"} key={`en_right_key_${i}`}>
                    <div className="tt">
                      {e.title}
                    </div>
                  {
                    e.child.map((e2, i2) => {
                      return (
                        <div className="case" key={`en_right_child_key_${i}_${i2}`}>
                          <span>▶</span>
                          <div className="bb">{e2}</div>
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="foot">
          <div className="fBlank"></div>
          <div className="bb">Back to top</div>
          <div className="ll">|</div>
          <div className="bb">E-mail services</div>
          <div className="ll">|</div>
          <div className="bb">Desktop tools</div>
          <div className="ll">|</div>
          <div className="bb">About Washington Tribune</div>
          <div className="ll">|</div>
          <div className="bb">News sources</div>
          <div className="ll">|</div>
          <div className="bb">Privacy and Policy</div>
          <div className="fBlank"></div>
        </div>
      </div>
    </div>
  );
}

export default NewsEng1;
