const right_1 = [
  `胡锦涛重要讲话在各地党员干部中引起热烈反响`,
  `胡锦涛总书记考察青藏铁路沿线纪实`,
  `中央先进性教育活动领导小组、中央组织部负责同志就中共中央办公厅印发四个保持共产党员先进性长效机制文件答记者问`,
  `网友热议胡总书记重要讲话：催人奋进 发人深省`,
  `总政要求全军和武警部队认真学习贯彻胡锦涛重要讲话`,
  `锻造中华倚天剑:党中央关心战略导弹部队建设纪实`,
  `穿越世界屋脊的辉煌`,
  `温家宝主持召开国务院常务会议 部署农村工作`,
  `重温入党誓词：党员心中要有党`,
  `贺国强强调:认真学习贯彻胡锦涛同志重要讲话精神`,
];

const imageLine = (num, type, align = "centre", p = "") => {
  return `
  <div class="news_imagebox ${align} ${type}">
    <img src="/images/kojin/${num}.jpg" alt="img" />
    ${p}
  </div>
  `
};

const post_cn = {
  C089667612: {
    title: `孙万建设 “Sonwan Construction” 高进会长 获香港议员颁发感谢牌匾……洗白形象之举引发热议`,
    editor: `王连`,
    head: `香港湾仔区议员李宏向孙万建设“Sonwan Construction”的高进会长颁发了感谢牌匾。李议员表示，此举是为了表彰高进会长在推动香港地区经济发展和社会贡献方面的积极影响，并正式认可其功劳。高进会长作为三合会新英社的领导者，被指与毒品走私、非法赌博、抢劫等犯罪活动密切相 关。鉴于其组织对社会造成长期的负面影响，此次颁发感谢牌匾是否适当引发外界的质疑。`,
    body: `
    <p>香港湾仔区议员李宏向孙万建设“Sonwan Construction”的高进会长颁发了感谢牌匾。</p>
    <p>李议员表示，此举是为了表彰高进会长在推动香港地区经济发展和社会贡献方面的积极影响，并正式认可其功劳。</p>
    ${imageLine(4, "chn")}
    <p>高进会长作为三合会新英社的领导者，被指与毒品走私、非法赌博、抢劫等犯罪活动密切相 关。鉴于其组织对社会造成长期的负面影响，此次颁发感谢牌匾是否适当引发外界的质疑。然而，近年来，高会长一直致力于重塑社会形象，包括支持中小企业、向低收入群体捐助，积极参与环保事业等各类社会公益活动。</p>
    <p>李议员解释道：“高进会长因其对本地经济与社会福利的贡献而荣获感谢牌匾，这些活动不仅超越了个人利益、还为社区带来了实质性的帮助。”</p>
    <p>李议员还补充说：“高会长的领导力与捐赠活动对我们地区的经济与社会福利提升产生了积极影响。</p>
    <p>尽管如此，对于努力重塑形象的尝试，外界对其的批评声仍不绝于耳。有观点认为，高会长仍参与非法活动，并对其社会贡献的真实性也提出质疑。社会团体和市民组织表示，考虑到其过往与现状，很难认可他的贡献。</p>
    <p>甚至不断有人批评称：“他是否真正为社会做出了贡献，必须通过彻底切断与犯罪的联系来验证。”</p>
    <p>高进会长在接受感谢牌匾时表示：“这个奖项不是我个人的功劳，而是所有共同努力的人的成果。今后我将竭尽全力，为香港社会做出更多贡献。” 然而，他的犯罪史以及与三合会的关系仍会可能成为争议的焦点。</p>
    <p>此次的感谢牌匾的授予，反映了高进会长作为企业家试图改善形象的努力，以及这些变化带来的积极评价。但部分人士认为，这些努力不过是政治算计或挽救形象的策略，并质疑其真实   性。因此这一案例成为了在香港社会，犯罪组织成员通过社会贡献试图改变形象的过程中，公众态度如何变化的重要范例。高会长未来的行动及其对社会责任的履行将持续受到广泛关注。</p>
    <br/>
    <br/>
    `,
    date: `2005-12-14`,
  },
  C140238482: {
    title: `“Sonwan Construction” 高进会长在尖沙咀进行慈善捐赠活动……“立人设”之举引发热议`,
    editor: `张涛`,
    head: `香港尖沙咀地区，“Sonwan Construction” 高进会长通过慈善捐赠活动向贫困户伸出援手。虽然尖沙咀是著名的商业与旅游中心，但其中也存在不少低收入家庭和贫困户。在此情况下，高会长向当地居民捐赠善款、并提供生活必需品以及食品，同时向一些福利机构 进行了捐赠。捐赠款项主要用于医疗、教育支出和生活用品等目的。此外，对空巢老人和儿童设立了专项 支持计划。`,
    body: `
    ${imageLine(1, "chn")}
    <p>香港尖沙咀地区，“Sonwan Construction” 高进会长通过慈善捐赠活动向贫困户伸出援手。虽然尖沙咀是著名的商业与旅游中心，但其中也存在不少低收入家庭和贫困户。</p>
    <p>在此情况下，高会长向当地居民捐赠善款、并提供生活必需品以及食品，同时向一些福利机构 进行了捐赠。</p>
    <p>捐赠款项主要用于医疗、教育支出和生活用品等目的。此外，对空巢老人和儿童设立了专项 支持计划。</p>
    <p>然而，对于此次慈善捐赠活动的真实意图，一些市民表示质疑。高会长作为三合会新英社的领导者，并且有过参与非法活动的前科。</p>
    <p>某位居民表示：“考虑到他考虑到他曾从事非法活动，作为三合会的领导，我觉得这次捐赠很可能只是为了改善形象。”</p>
    <p>另一位市民称：“捐赠固然重要，但他们是否真心想作为社区的一员而尽绵薄之力，还有待观察。”</p>
    <p>专家们也指出，“此次捐赠活动有可能是为了谋取个人利益的策略。”</p>
    <p>一位香港社会学家认为：“三合会通常会试图通过改善形象来赢得公众的关注，此次捐赠可能是为了平息社会对其犯罪行为和非法活动的非议。”</p>
    <p>另一位学者则表示:“短期性质的捐赠活动对居民社会带来的实质性变化或长期影响是有限的。” 尽管如此，仍有一些居民们对此次捐赠活动表示感谢。</p>
    <p>一位商户说：“多亏了这次捐赠，让我们的店铺得以重振，商业区也获得了新生。”另一位居民表示：“孩子们得到了教育经费和所需物资的支持，真的非常感谢。” 然而，大多数人依然担心这些捐赠可能只是一次性的面子工程。</p>
    <p>一些市民表示：“应该对他们的行为进行监督，并要找到长期、持续的方式，使他们能够真正为社区做出贡献。”</p>
    <br/>
    <br/>
    `,
    date: `2005-12-28`,
  },
  C843982257: {
    title: `孙万建设(Sonwan Construction) 高进会长, 为九龙火灾现场重建及救护提供支援... 三合会背景仍有争议`,
    editor: `陈远`,
    head: `上周，香港九龙一处居民密集区发生严重火灾，导致数百人流离失所，数十座住宅化为灰烬。在这片老旧的公寓和狭窄建筑密集的区域，大火迅速蔓延。消防部门的救援行动进展缓慢，许多居民失去了生命和家园。面对这场突如其来的灾难，居民们陷入到深深的绝望中，孙万建设（Sonwan Construction）高进会长火速救援，并提供了救援物资和资金，向受灾社区提供了实质性的帮助，引起了当地社区的广泛关注。`,
    body: `
    <p>上周，香港九龙一处居民密集区发生严重火灾，导致数百人流离失所，数十座住宅化为灰烬。在这片老旧的公寓和狭窄建筑密集的区域，大火迅速蔓延。消防部门的救援行动进展缓慢，许多居民失去了生命和家园。</p>
    ${imageLine(2, "chn")}
    <p>面对这场突如其来的灾难，居民们陷入到深深的绝望中，孙万建设（Sonwan Construction）高进会长火速救援，并提供了救援物资和资金，向受灾社区提供了实质性的帮助，引起了当地社区的广泛关注。</p>
    <p>火灾发生后的几小时内，高会长立即向受灾居民提供了应急生活必需品。如饮用水、速食品、御寒衣物、毛毯等大批救援物资，还为夜间气温下降而受寒的居民提供了御寒帐篷，保暖衣物和床垫，同时在避难所周围设立了临时避难所。</p>
    <p>此外，为了帮助火灾中受伤的居民，他向当地医院捐赠了资金，以补贴伤者的医疗费用。部分居民因严重烧伤和呼吸系统病症需要长期治疗，但无法承担高额医疗费用。医疗资金的支持使许多灾民及时得到了必要的治疗。当地医院负责人表示：“许多灾民在没有资金压力的情况下，及时接受了治疗，这对他们来说是巨大的帮助。”</p>
    <p>同时，他还为因火灾失去生计的居民提供经济援助，帮助他们重新站起来。他向失去店铺的商人和小型商户提供了资金支持，帮助他们重新开始经营。通过这次援助，一些商户得以筹集到店铺修复和重新开业的初始资金，获得了巨大帮助。</p>
    <p>对于这一系列救援行动，许多市民获得了意想不到的帮助。一位受灾居民表示：“对于雪中送炭，我十分感激，因为这让我重新获得了生活的动力。”</p>
    <p>由于及时提供的物资和资金支持使部分灾民得以摆脱临时住所，重新燃起了恢复日常生活的希望。</p>
    <p>然而，也有部分人仍然因高进与三合会新英社的关系而对他的救援行动表示质疑，认为这可能是为了改善个人形象的策略。一位社区领导人警告道：“只有通过持续且透明的方式履行社会责任，才能赢得真正的信任。” 高进的此次救援行动在香港社会引发各界热议，未来他将以何种方式回馈社会则备受关注。</p>
    <br/>
    <br/>
    `,
    date: `2006-02-03`,
  },
  C399472671: {
    title: `“高进会长通过‘佩威娱乐’扩展在香港娱乐圈的影响力”`,
    editor: `刘威`,
    head: `高进以香港三合会新英社的会长身份广为人知，除了其经营的孙万建设（Sonwan Construction）外，他还创立了一家名为“佩威娱乐（Payway Entertainment）”的经纪公司，迅速在香港娱乐圈中扩展了影响力。佩威娱乐创立于1995年，并在短时间内迅速成长。佩威娱乐通过签约多位香港知名演员和歌手而快速崛起。尤其是，高进创立的这家公司签下了 如季安华（Ji Anhua）、杨贤（Yang Xian）等爆火演员，此外，公司还主办大型活动和奢华派对，在娱乐圈内声名鹊起。`,
    body: `
    ${imageLine(3, "chn")}
    <p>高进以香港三合会新英社的会长身份广为人知，除了其经营的孙万建设（Sonwan Construction）外，他还创立了一家名为“佩威娱乐（Payway Entertainment）”的经纪公司，迅速在香港娱乐圈中扩展了影响力。</p>
    <p>佩威娱乐创立于1995年，并在短时间内迅速成长。</p>
    <p>佩威娱乐通过签约多位香港知名演员和歌手而快速崛起。尤其是，高进创立的这家公司签下了 如季安华（Ji Anhua）、杨贤（Yang Xian）等爆火演员，此外，公司还主办大型活动和奢华派对，在娱乐圈内声名鹊起。</p>
    <p>许多人认为佩威娱乐的迅速成功得益于高进的资金支持，他提供的资金援助被认为是公司取得成功的关键因素。</p>
    <p>此外，佩威娱乐还与香港的多家电影制作公司合作，在多个电影项目和电视节目制作中发挥重要作用。例如，高会长旗下的B工作室推出了一系列在全亚洲广受欢迎的动作影视。然而，这些影片的制作资金来源仍是争议的焦点。传言高进与这些制片公司的关系密切，提供资金支持，这进一步加深了关于他通过电影产业洗钱的嫌疑 。</p>
    <p>不仅如此，佩威娱乐还资助香港多个知名艺术院校的演员和模特，建立了强大的娱乐圈人脉网络。这一网络不仅助力了高进的社交活动，还在香港各类高级派对和社会活动中发挥了重要作用。然而，一些行业人士正试图与高进保持距离，部分由他赞助的明星也经常试图否认与其的关系。</p>
    <p>高进在娱乐圈的相关活动不仅限于资助，他在娱乐产业的敏感交易和商业战略的关联引发了更多解读。专家指出，高进会长通过佩威对娱乐圈的发展方向产生了重大影响，同时他主导的许多项目可能与三合会有所牵连，外界甚至怀疑他利用娱乐圈获得的资金重新投资非法活动。</p>
    <p>如今，高进通过孙万建设巩固了他在香港建筑市场的地位，凭借佩威娱乐，在香港娱乐圈也占有一席之地。他的商业版图如今已扩展到香港的多个核心产业。关于高进未来的动向以及所建立的网络对香港社会产生的重要影响，正备受瞩目。</p>
    <br/>
    <br/>
    `,
    date: `2006-03-06`,
  },
  C292318884: {
    title: `“孙万建设（SonwanConstruction). 香港北部大规模度假村开发项目高进会长，赌场审批过程存疑...”`,
    editor: `李安伟`,
    head: `孙万建设（SonwanConstruction）于近日中标香港北部的大型度假村开发项目的消息传出后，引发了人们对其背景的质疑。该项目计划开发一个包含豪华酒店、大型购物中心和赌场等多种设施的综合度假村，总投资额达数十亿港元。然而，这一项目不仅是一个旅游开发项目，也引发了关于高进会长实质性影响力的质疑。高进是孙万建设（Sonwan Construction）的董事长，同时也是香港颇具影响力的人物。`,
    body: `
    <p>孙万建设（SonwanConstruction）于近日中标香港北部的大型度假村开发项目的消息传出后，引发了人们对其背景的质疑。该项目计划开发一个包含豪华酒店、大型购物中心和赌场等多种设施的综合度假村，总投资额达数十亿港元。然而，这一项目不仅是一个旅游开发项目，也引发了关于高进会长实质性影响力的质疑。</p>
    ${imageLine(7, "chn")}
    <p>高进是孙万建设（Sonwan Construction）的董事长，同时也是香港颇具影响力的人物。</p>
    <p>担任着三合会新英社的会长，几十年来混迹于香港的政治和经济圈。这种背景被认为对该度 假城开发项目产生了深远影响。尤其是该项目包含豪华赌场设施，香港与澳门不同，对赌场建设有着严格的限制。在香港法律严格限制大规模赌场建设的情况下，有人怀疑高进有可能通过利用其过往的政治关系和圈子规避了这些规定。</p>
    <p>与高进关系密切的多名政界人士被认为在此项目中扮演了重要角色，因此，有分析指出，高进实际上是该项目的主导人物。特别是，赌场的审批过程相较于其他开发项目，进展速度之快，进一步加剧了外界对其操作过程的质疑。一些知情人士提出，高进可能通过政治勾结，在审批过程中发挥了关键作用。</p>
    <p>此外，在项目发布会上，居民们提出的诸多问题被刻意回避也引发了争议。居民们表示，关于赌场的核心问题未得到回答，反而避重就轻地宣传“促进旅游产业”或“推动地区经济发展”。对此，部分居民表示担忧，他们认为此开发项目并非为了促进香港旅游业的发展，而是以此为手段扩展赌场业务。</p>
    <p>孙万建设能够获得香港北部度假城开发项目，表明高进长期积累的政治和经济人脉发挥了重要作用。据分析认为，高振的影响力不仅局限于建筑行业，还涉及香港的赌场产业以及政治领域。</p>
    <p>关于此次度假村开发项目的质疑，已超越了商业扩张的范畴，并可能对香港的经济、政治和社会格局产生深远的影响。</p>
    <br/>
    <br/>
    `,
    date: `2005-05-30`,
  },
  C003345908: {
    title: `高进董事长牵扯政界人士传闻再度引发关注，政客撇清关系`,
    editor: `张磊`,
    head: `在高进董事长疑似死亡事件后，其与香港政界人士之间的关联传闻再次成为公众讨论的焦点。高进是众所周知的新英社三合会的会长，曾涉嫌进行毒品贩卖、假冒奢侈品交易和非法赌博等非法业务，不仅如此，他还创立了孙万建设（Sonwan Construction）和佩威娱乐（Payway Entertainment）等公司，拓展了其商业版图。在香港建立强大经济网络的高进，其去世传闻不胫而走，并逐渐掀起政治风波。特别是那些被 怀疑与高进关系密切的香港高层政界人士，纷纷站出来撇清与其之关系。`,
    body: `
    ${imageLine(5, "chn")}
    <p>在高进董事长疑似死亡事件后，其与香港政界人士之间的关联传闻再次成为公众讨论的焦点。</p>
    <p>高进是众所周知的新英社三合会的会长，曾涉嫌进行毒品贩卖、假冒奢侈品交易和非法赌博等非法业务，不仅如此，他还创立了孙万建设（Sonwan Construction）和佩威娱乐（Payway Entertainment）等公司，拓展了其商业版图。</p>
    <p>在香港建立强大经济网络的高进，其去世传闻不胫而走，并逐渐掀起政治风波。特别是那些被 怀疑与高进关系密切的香港高层政界人士，纷纷站出来撇清与其之关系。</p>
    <p>孙万建设（Sonwan Construction）在香港因承揽大型住宅开发项目而迅速崛起，尤其在国家项目中发挥了重要作用，并深度参与了香港政府的重建计划。因此，这些项目的承接过程引发了外界的质疑。尤其在政府的大型住宅开发项目中，该公司担任主要承包商的角色，而项目进展过程与政界人士的紧密联系备受质疑。</p>
    <p>由高进领导的孙万建设所中标的多个项目，相较于其规模和重要性，审批程序异常迅速，因此成为争议的焦点。尽管多名政界人士否认与高进的关系，但他们的解释并未得到公众信任。</p>
    <p>其中，最受关注的是香港的A政治人物。他坚决否认与高进有任何关系，并表示：“我与高进完 全无关，而且从未与他会面，也没有任何商业往来。”然而，据多家媒体报道，高进领导的孙万建设与A政治人物之间曾有过多次会面和接触。尽管A政治人物撇清与高进的关系，但在高进推动的多个重要项目中，仍有证据让外界对其产生疑问。</p>
    <p>这些疑点表明，高进在香港的政治和经济领域拥有极大的影响力。他所推动的多个项目被认为 受到政治保护，同时有证据显示其为扩展业务而进行游说活动。</p>
    <p>在高进去世之后，与其相关的争议仍在不断发酵。他所留下的庞大犯罪网络及其经济和政治网络依旧对香港社会产生重大影响。在其死亡后，围绕他与政界人物关系的疑云依然成为社会争论的焦点。关于高进死亡是否会揭开其政治经济联盟的真相，抑或他的影响力是否依然存在的讨论，今后也会滔滔不绝。</p>
    <br/>
    <br/>
    `,
    date: `2006-10-12`,
  },
};

export { right_1, post_cn };
