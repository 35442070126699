import { useEffect } from "react";
import { strongStr } from "../../common/modules/js/commonFunc";
import { v4 as uuidv4 } from 'uuid';

export default function OgResult({ obj, keypoint }) {
  const id = uuidv4();

  const idTitle = "news_title_id_" + id;
  const idBody = "news_body_id_" + id;
  
  const onClickFunc = () => {
    if (obj.loca) {
      window.open(obj.loca);
      return;
    } else {
      return;
    }
  }

  useEffect(() => {
    if (keypoint) {
      let newTitle = obj.title;
      let newText = obj.text;

      keypoint.split(/ /).forEach((e) => {
        newTitle = newTitle.replaceAll(e, strongStr(e));
        newText = newText.replaceAll(e, strongStr(e));
      });

      document.getElementById(idTitle).innerHTML = newTitle;
      if (obj.text !== undefined && obj.text !== null) {
        document.getElementById(idBody).innerHTML = newText;
      }
    } else {
      document.getElementById(idTitle).innerHTML = obj.title;
      document.getElementById(idBody).innerHTML = obj.text;
    }
  });

  return (
    <div className="OgResult">
      <div id={idTitle} onClick={onClickFunc} className="title">
        {/* auto fill */}
      </div>
      <div id={idBody} className="text">
        {/* auto fill */}
      </div>
      <div className="more">
        {
          obj.loca ?
          <>
            <div className="link" onClick={onClickFunc}>{obj.loca}</div>
            <div className="gb">ㆍ</div>
          </>
          :
          <></>
        }
        <div className="gb bb">Cached</div>
        <div className="gb">ㆍ</div>
        <div className="gb bb">Similar pages</div>
      </div>
    </div>
  )
}